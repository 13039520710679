import React from "react";
const Banner = () => {
  return (
    <div className="bg-blue-500">
      <div className="py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <div className="md:w-[600px] h-[200px] mx-auto ">
          <h1 className="text-3xl font-extrabold text-center text-white">
            Réservez une consultation physique ou vidéo avec un professionnel de
            santé
          </h1>
          <div className="mt-8 flex lg:flex-shrink-0 items-center justify-center">
            <div className="inline-flex rounded-md shadow">
              <a
                href="/search"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-500 hover:bg-orange-400"
              >
                Prendre Rendez-vous
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
