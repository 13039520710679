import React from "react";

import { MyTextInput } from "../components/Inputs";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { alertService } from "../services/alert";
import { history } from "../helpers/history";

import { postRequest } from "../helpers/apiRequests";
import { CHECK_EMAIL } from "../constants/apiEndpoints";

import * as Yup from "yup";

const VerifyEmail = () => {
  const initialValues = {
    token: "",
  };

  const validationSchema = Yup.object({
    token: Yup.string().required("Renseigner le code"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    alertService.clear();

    const response = await postRequest(`${CHECK_EMAIL}`, values);
    if (response.error) {
      setSubmitting(false);
      alertService.error("Identifiant incorrecte veuillez reesayer");
    } else {
      alertService.success(
        "Votre compte utilisateur a bien ete validee veuillez vous connectez",
        { keepAfterRouteChange: true }
      );
      const { from } = history.location.state || {
        from: { pathname: "/login" },
      };
      history.push(from);
    }
  };
  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <p className="bg-orange-100 w-full">
          Inscription réussie, veuillez vérifier votre e-mail et entrer le code
          de vérification
        </p>
        <div className="w-full">
          <h1 className="mt-6 text-3xl font-extrabold text-gray-900">
            Verification de votre email adresse !
          </h1>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="flex flex-col my-2 space-y-2">
                <label htmlFor="token">Entrer votre code de vérification</label>
                <Field
                  name="token"
                  type="text"
                  placeholder="Token de vérification"
                />
                <ErrorMessage className="text-red-500" name="token" />
              </div>
              <div className="flex">
                <button
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  type="submit"
                >
                  VERIFICATION
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
