import React, { useState, useEffect } from "react";
import FormLayout from "../../layouts/FormLayout";
import { Link } from "react-router-dom";

import { MyTextInput, MyCheckbox } from "../Inputs";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { alertService } from "../../services/alert";
import { history } from "../../helpers/history";

import { countryList } from "../../Countries";

import {
  ADD_PATIENT,
  EDIT_PATIENT,
  GET_PATIENT,
  GET_COMPANY_LIST,
  GET_SERVICE_LIST,
} from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../helpers/apiRequests";

import * as Yup from "yup";

const CompanyForm = ({ history, match }) => {
  const request = FetchWrapper();
  const { id } = match.params;
  const isAddMode = !id;

  const [countries, setCountries] = useState(countryList);

  const initialValues = {
    role: "patient",
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    birthday: "",
    country: "",
    gender: "",
    work: "",
    maritalStatus: "",
    password: "",
    medicalCover: false,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Champs requis"),
    lastName: Yup.string().required("Champs requis"),
    email: Yup.string()
      .email("Cette adresse email est invalide")
      .required("Champs requis"),
    telephone: Yup.string().required("Champs requis"),
    birthday: Yup.string().required("Champs requis"),
    country: Yup.string().required("Champs requis"),
    gender: Yup.string().required("Champs requis"),
  });

  const handleSubmit = (values, { setStatus, setSubmitting, resetForm }) => {
    setStatus();
    if (isAddMode) {
      createPatient(values, setSubmitting);
    } else {
      updatePatient(id, values, setSubmitting);
    }
  };

  const createPatient = async (values, setSubmitting) => {
    alertService.clear();
    try {
      const response = await request.post(ADD_PATIENT, values);
      if (response) {
        alertService.success("Nouveau patient creer", {
          keepAfterRouteChange: true,
        });
        const { from } = history.location.state || {
          from: { pathname: "." },
        };
        history.push(from);
      }
    } catch (error) {
      setSubmitting(false);
      alertService.error(error.message);
    }
  };
  const updatePatient = async (id, values, setSubmitting) => {
    alertService.clear();
    try {
      const response = await request.post(EDIT_PATIENT + id, values);
      if (response) {
        alertService.success("Mise a jour effectuer", {
          keepAfterRouteChange: true,
        });
        const { from } = history.location.state || {
          from: { pathname: ".." },
        };
        history.push(from);
      }
    } catch (error) {
      setSubmitting(false);
      alertService.error(error.message);
    }
  };
  return (
    <FormLayout
      title={
        isAddMode ? "Ajouter un patient" : "Modifier les infos. du patient"
      }
      path={isAddMode ? "." : ".."}
      description="veuillez renseigner les informations ci-dessous"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {function Render({ isSubmitting, setFieldValue }) {
          const [patient, setPatient] = useState({});

          useEffect(async () => {
            if (!isAddMode) {
              const response = await request.get(GET_PATIENT + id);
              setPatient(response);
              if (response) {
                const fields = [
                  "firstName",
                  "lastName",
                  "gender",
                  "email",
                  "telephone",
                  "birthday",
                  "country",
                ];
                fields.forEach((field) => {
                  if (field === "birthday") {
                    let date = new Date(response[field]);
                    let day = date.getDate();
                    let month = date.getMonth() + 1;
                    let year = date.getFullYear();
                    setFieldValue(field, year + "-" + month + "-" + day, false);
                  } else {
                    setFieldValue(field, response[field], false);
                  }
                });
              }
            }
          }, []);

          return (
            <Form>
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <div className="row mb-3">
                        <label
                          className="col-sm-2 col-form-label"
                          htmlFor="firstName"
                        >
                          Nom
                        </label>
                        <div className="col-sm-5">
                          <Field
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                            name="firstName"
                            placeholder="Nom de famille"
                            type="text"
                          />
                          <ErrorMessage name="firstName" />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          className="col-sm-2 col-form-label"
                          htmlFor="lastName"
                        >
                          Prenoms
                        </label>
                        <div className="col-sm-5">
                          <Field
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                            name="lastName"
                            placeholder="Prenoms"
                            type="text"
                          />
                          <ErrorMessage name="lastName" />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          className="col-sm-2 col-form-label"
                          htmlFor="gender"
                        >
                          Sexe
                        </label>
                        <div className="col-sm-5">
                          <Field
                            as="select"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                            name="gender"
                            id="gender"
                          >
                            <option value="">Choisir le sexe</option>
                            <option value="M">Masculin</option>
                            <option value="F">Feminin</option>
                          </Field>
                          <ErrorMessage name="gender" />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          className="col-sm-2 col-form-label"
                          htmlFor="birthday"
                        >
                          Date de naissance
                        </label>
                        <div className="col-sm-5">
                          <Field
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                            name="birthday"
                            type="date"
                          />
                          <ErrorMessage name="birthday" />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          className="col-sm-2 col-form-label"
                          htmlFor="country"
                        >
                          Choisir votre nationalite
                        </label>
                        <div className="col-sm-5">
                          <Field
                            as="select"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                            name="country"
                            id="country"
                          >
                            <option value="">Choisir dans la liste</option>
                            {countries &&
                              countries.map((country, index) => (
                                <option key={index} value={country.name}>
                                  {country.name}
                                </option>
                              ))}
                          </Field>
                          <ErrorMessage name="country" />
                        </div>
                      </div>
                      <fieldset>
                        <div className="py-5">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Compte utilisateur
                          </h3>
                          <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            Informations sur le compte utilisateur pour se
                            connecter
                          </p>
                        </div>
                        <div>
                          <div className="row mb-3">
                            <label
                              className="col-sm-2 col-form-label"
                              htmlFor="telephone"
                            >
                              Telephone
                            </label>
                            <div className="col-sm-5">
                              <Field
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                                name="telephone"
                                type="text"
                              />
                              <ErrorMessage name="telephone" />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              className="col-sm-2 col-form-label"
                              htmlFor="email"
                            >
                              Email adresse
                            </label>
                            <div className="col-sm-5">
                              <Field
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                                name="email"
                                type="text"
                              />
                              <ErrorMessage name="email" />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              className="col-sm-2 col-form-label"
                              htmlFor="password"
                            >
                              Mot de passe
                            </label>
                            <div className="col-sm-5">
                              <Field
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                                name="password"
                                type="password"
                              />
                              <ErrorMessage name="password" />
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>

              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Enregistrer
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </FormLayout>
  );
};

export default CompanyForm;
