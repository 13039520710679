import React from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { alertService } from "../services/alert";
import { FORGOT_PASSWORD } from "../constants/apiEndpoints";
import { FetchWrapper } from "../helpers/apiRequests";

function ForgotPassword({ history, match }) {
  const request = FetchWrapper();
  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Le courriel est invalide")
      .required("Email is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    alertService.clear();
    try {
      const response = await request.postRequest(`${FORGOT_PASSWORD}`, values);
      if (response.error) {
        setSubmitting(false);
        alertService.error(response.message);
      } else {
        alertService.success(
          "Veuillez vérifier votre courrier électronique pour les instructions de réinitialisation du mot de passe",
          { keepAfterRouteChange: true }
        );
        const { from } = history.location.state || {
          from: { pathname: "/checkemail" },
        };
        history.push(from);
      }
    } catch (e) {
      setSubmitting(false);
      alertService.error(e);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <h3 className="card-header">mot de passe oublié</h3>
          <div className="card-body">
            <div className="form-group">
              <label>Email</label>
              <Field
                name="email"
                type="text"
                className={
                  "form-control" +
                  (errors.email && touched.email ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="email"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-row">
              <div className="form-group col">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary"
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Submit
                </button>
                <Link to="login" className="btn btn-link">
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export { ForgotPassword };
