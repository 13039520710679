import React from "react";
import { MyTextInput, MyCheckbox } from "../components/Inputs";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { alertService } from "../services/alert";
import { history } from "../helpers/history";

import { postRequest } from "../helpers/apiRequests";
import { REGISTER } from "../constants/apiEndpoints";

import * as Yup from "yup";

const Register = () => {
  const initialValues = {
    role: "patient",
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    password: "",
    passwordConfirmation: "",
    acceptedTerms: false,
    rememberMe: true,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Le nom de famille est requis"),
    lastName: Yup.string().required("Le prenom de famille est requis"),
    telephone: Yup.string().required("Le numero de telephone est requis"),
    email: Yup.string()
      .email("Cette adresse email est invalide")
      .required("Required"),
    acceptedTerms: Yup.bool()
      .oneOf([true])
      .required("Veuillez accpeter nos termes"),
    password: Yup.string().required("Renseigner le mot de passe"),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Le mot de passe n'est pas conforme")
      .required("Renseigner le mot de passe de confirmation"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    alertService.clear();
    try {
      const response = await postRequest(`${REGISTER}`, values);
      if (response.error) {
        setSubmitting(false);
        alertService.error(response.error.message);
      } else {
        alertService.success(
          "Inscription réussie, veuillez vérifier votre e-mail pour les instructions de vérification",
          { keepAfterRouteChange: true }
        );
        const { from } = history.location.state || {
          from: { pathname: "/checkemail" },
        };
        history.push(from);
      }
    } catch (e) {
      setSubmitting(false);
      alertService.error(e);
    }
  };
  return (
    // <div className="Sign">
    //   <div className="Sign__Card">
    //     <div className="Sign__Content">
    //       <h1>Déjà un compte</h1>
    //       <div className="Sign__Footer">
    //         <a href="/login" className="Blue__Link">
    //           Se connecter
    //         </a>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="Sign__Card">
    //     <div className="Sign__Content">
    //       <h1>S'enregistrer</h1>
    //       <p>Saisissez vos informations pour continuer</p>
    //       <Formik
    //         initialValues={initialValues}
    //         validationSchema={validationSchema}
    //         onSubmit={handleSubmit}
    //       >
    //         {({ errors, touched, isSubmitting }) => (
    //           <Form>
    //             <MyTextInput
    //               name="firstName"
    //               type="text"
    //               placeholder="Nom de famille"
    //             />
    //             <MyTextInput
    //               name="lastName"
    //               type="text"
    //               placeholder="Prenoms"
    //             />
    //             <MyTextInput
    //               name="telephone"
    //               type="text"
    //               placeholder="Numero de telephone"
    //             />
    //             <MyTextInput
    //               name="email"
    //               type="email"
    //               placeholder="Email Address"
    //             />
    //             <MyTextInput
    //               name="password"
    //               type="password"
    //               placeholder="Mot de passe"
    //             />
    //             <MyTextInput
    //               name="passwordConfirmation"
    //               type="password"
    //               placeholder="Confirmer le Mot de passe"
    //             />
    //             <MyCheckbox name="acceptedTerms">
    //               J'accepte les termes et conditions
    //             </MyCheckbox>
    //             <MyCheckbox name="rememberMe">
    //               Se souvenir de mon identifiant
    //             </MyCheckbox>

    //             <button
    //               type="submit"
    //               disabled={isSubmitting}
    //               className="btn btn-primary"
    //             >
    //               {isSubmitting && (
    //                 <span className="spinner-border spinner-border-sm mr-1"></span>
    //               )}
    //               S'Inscrire
    //             </button>
    //           </Form>
    //         )}
    //       </Formik>
    //     </div>
    //   </div>
    // </div>
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <p className="bg-orange-100 w-full">
          Pour prendre rendez-vous, vous devez avoir un compte
        </p>
        <div className="w-full">
          <h1 className="mt-6 text-3xl font-extrabold text-gray-900">
            S'enregistrer
          </h1>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <div className="flex flex-col my-2 space-y-2">
                  <label htmlFor="firstName">Nom de famille</label>
                  <Field
                    name="firstName"
                    type="text"
                    placeholder="Nom de famille"
                  />
                  <ErrorMessage name="firstName" />
                </div>

                <div className="flex flex-col my-2 space-y-2">
                  <label htmlFor="lastName">Prénoms</label>
                  <Field name="lastName" type="text" placeholder="Prénoms" />
                  <ErrorMessage name="lastName" />
                </div>

                <div className="flex flex-col my-2 space-y-2">
                  <label htmlFor="telephone">Téléphone</label>
                  <Field name="telephone" type="text" placeholder="Téléphone" />
                  <ErrorMessage name="telephone" />
                </div>

                <div className="flex flex-col my-2 space-y-2">
                  <label htmlFor="email">Adresse email</label>
                  <Field name="email" type="text" placeholder="Téléphone" />
                  <ErrorMessage name="email" />
                </div>

                <div className="flex flex-col my-2 space-y-2">
                  <label htmlFor="password">Mot de passe</label>
                  <Field
                    name="password"
                    type="password"
                    placeholder="Mot de passe"
                  />
                  <ErrorMessage name="password" />
                </div>

                <div className="flex flex-col my-2 space-y-2">
                  <label htmlFor="passwordConfirmation">
                    Confirmer votre mot de passe
                  </label>
                  <Field
                    name="passwordConfirmation"
                    type="password"
                    placeholder="Confirmer votre mot de passe"
                  />
                  <ErrorMessage name="passwordConfirmation" />
                </div>

                <div className="flex flex-col my-2 space-y-2">
                  <MyCheckbox name="acceptedTerms">
                    J'accepte les termes et conditions
                  </MyCheckbox>
                </div>

                <div className="flex flex-col my-2 space-y-2">
                  <MyCheckbox name="rememberMe">
                    Se souvenir de mon identifiant
                  </MyCheckbox>
                </div>
                <div className="flex">
                  <button
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                    S'INSCRIRE
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="my-5 flex items-center justify-between">
            <a
              href="/login"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-blue-500 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Connectez-vous à votre compte Telmed.
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
