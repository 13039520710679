import React, { useState, useEffect } from "react";
import { GET_REQUEST } from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../helpers/apiRequests";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { formatToDate } from "../../helpers/utility";

const RequestDetail = ({ match }) => {
  const { path } = match;
  const { id } = match.params;
  const [request, setRequest] = useState({});
  const fetchWrapper = FetchWrapper();

  useEffect(() => {
    async function fetchData() {
      const response = await fetchWrapper.get(GET_REQUEST + id);
      setRequest(response);
    }
    fetchData();
  }, []);

  return (
    <div className="my-10">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mb-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <a className="mr-5" href=".">
              <ArrowBackIcon className="Icon" />
            </a>
            <div className="Profile__Info">
              <div>
                <h3 className="text-2xl font-bold">{request.motif}</h3>
                <p>{formatToDate(request.date)}</p>
              </div>
            </div>
          </div>
          <div className="Profile__CoverActions">
            {request.status === "pending" ? (
              <div className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800">
                EN ATTENTE
              </div>
            ) : (
              ""
            )}
            {request.status === "accepted" ? (
              <div className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                ACCEPTER
              </div>
            ) : (
              ""
            )}
            {request.status === "cancelled" ? (
              <div className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                ANNULER
              </div>
            ) : (
              ""
            )}
            {request.appointment && (
              <a href={`"/appointment/"${request.appointment.id}`}>
                Aller au RDV
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Information generale
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Personal details and application.
            </p>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Motif</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {request.motif}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Date</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {formatToDate(request.date)}
                </dd>
              </div>
            </dl>
          </div>
          {request.patient && (
            <>
              <div className="px-4 py-5 sm:px-6 border-t border-gray-200">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Patient
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Details sur le patient
                </p>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      <PersonOutlinedIcon className="ProfileIcon" />
                      Nom
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {request.patient.firstName}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      <PersonOutlinedIcon className="ProfileIcon" />
                      Prenoms
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {request.patient.lastName}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      <EmailOutlinedIcon className="ProfileIcon" />
                      Email addresse
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {request.patient.email}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      <CallOutlinedIcon className="ProfileIcon" />
                      Telephone
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {request.patient.telephone}
                    </dd>
                  </div>
                </dl>
              </div>
            </>
          )}
          {request.doctor && (
            <>
              <div className="px-4 py-5 sm:px-6 border-t border-gray-200">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Docteur
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Details sur le docteur
                </p>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      <PersonOutlinedIcon className="ProfileIcon" />
                      Nom
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {request.doctor.firstName}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      <PersonOutlinedIcon className="ProfileIcon" />
                      Prenoms
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {request.doctor.lastName}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      <EmailOutlinedIcon className="ProfileIcon" />
                      Email addresse
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {request.doctor.email}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      <CallOutlinedIcon className="ProfileIcon" />
                      Telephone
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {request.doctor.telephone}
                    </dd>
                  </div>
                </dl>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestDetail;
