import React from "react";
import { Route, Switch } from "react-router-dom";
import ContactForm from "../components/Demo/ContactForm";
import DetailForm from "../components/Demo/DetailForm";

const Demo = ({ match }) => {
  const { path } = match;
  return (
    <Switch>
      <Route exact path={`${path}/contact`} component={ContactForm} />
      <Route exact path={path} component={DetailForm} />
    </Switch>
  );
};

export default Demo;
