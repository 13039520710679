// import React, { useState, useEffect } from "react";
import PageLayout from "../../layouts/PageLayout";

import { Link } from "react-router-dom";

import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined";

import { GET_REQUEST_ME, REMOVE_REQUEST } from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../helpers/apiRequests";
import { formatToDate } from "../../helpers/utility";
import { useEffect, useState } from "react";

/* This example requires Tailwind CSS v2.0+ */
const people = [
  {
    name: "Jane Cooper",
    title: "Regional Paradigm Technician",
    department: "Optimization",
    role: "Admin",
    email: "jane.cooper@example.com",
    image:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
  },
  // More people...
];

export default function RequestList({ match }) {
  const { path } = match;

  const request = FetchWrapper();
  const [rows, setRows] = useState([]);

  const actions = [{ url: "/search", title: "Nouvelle Demande" }];

  useEffect(async () => {
    const response = await request.get(GET_REQUEST_ME);
    setRows(response);
  }, []);

  const handleDelete = async (id) => {
    setRows(
      rows.map((x) => {
        if (x.id === id) {
          x.isDeleting = true;
        }
        return x;
      })
    );
    const response = await request.remove(REMOVE_REQUEST + id, { id });
    if (response) {
      setRows((rows) => rows.filter((x) => x.id !== id));
    }
  };
  return (
    <PageLayout
      title="Demandes de Rdv"
      description="liste de toutes les demandes de Rendez-vous"
      actions={actions}
    >
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Nom du patient
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Motifs de la demande
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Statut
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {rows &&
                    rows.map((row) => (
                      <tr key={row.id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 h-10 w-10">
                              <img
                                className="h-10 w-10 rounded-full"
                                src={row.image}
                                alt=""
                              />
                            </div>
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">
                                {row.patient.firstName +
                                  " " +
                                  row.patient.lastName}
                              </div>
                              <div className="text-sm text-gray-500">
                                {row.patient.email}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {row.motif}
                          </div>
                          <div className="text-sm text-gray-500">
                            {formatToDate(row.date)}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {row.status === "pending" ? (
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800">
                              EN ATTENTE
                            </span>
                          ) : (
                            ""
                          )}
                          {row.status === "accepted" ? (
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                              ACCEPTER
                            </span>
                          ) : (
                            ""
                          )}
                          {row.status === "cancelled" ? (
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                              ANNULER
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <div className="flex items-center">
                            <Link
                              className="h-8 w-8 flex items-center justify-center rounded-full border-2 border-gray p-2 ml-1 bg-gray-100 text-blue-600 hover:bg-gray-200"
                              to={`${path}/${row.id}`}
                            >
                              <ContentPasteSearchOutlinedIcon />
                            </Link>
                            {row.status === "pending" ? (
                              <button
                                onClick={() => handleDelete(row.id)}
                                className="h-8 w-8 flex items-center justify-center rounded-full border-2 border-blue-500 p-2 ml-1 bg-blue-500 text-white hover:bg-blue-600"
                                disabled={row.isDeleting}
                              >
                                {row.isDeleting ? (
                                  <span className="spinner-border spinner-border-sm"></span>
                                ) : (
                                  <DeleteOutlineOutlinedIcon />
                                )}
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  {!rows && (
                    <tr>
                      <td colSpan="4" className="text-center">
                        <div className="spinner-border spinner-border-lg align-center"></div>
                      </td>
                    </tr>
                  )}
                  {rows && !rows.length && (
                    <tr>
                      <td colSpan="4" className="text-center">
                        <div className="p-2">Aucun enregistrement</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
