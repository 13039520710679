import React, { useContext } from "react";

import { MyTextInput, MyCheckbox } from "../components/Inputs";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { alertService } from "../services/alert";
import { history } from "../helpers/history";

import { AuthContext } from "../context/AuthContext";

import axios from "axios";
import { LOGIN } from "../constants/apiEndpoints";
import { LockClosedIcon } from "@heroicons/react/solid";

import * as Yup from "yup";

const Login = () => {
  const { dispatch } = useContext(AuthContext);

  const initialValues = {
    role: "patient",
    email: "",
    password: "",
    rememberMe: true,
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Cette adresse email est invalide")
      .required("Renseigner l'adresse e-mail"),
    password: Yup.string().required("Renseigner le mot de passe"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    alertService.clear();
    dispatch({ type: "LOGIN_START" });
    try {
      const response = await axios.post(LOGIN, values);

      alertService.success("...", { keepAfterRouteChange: true });
      const { from } = history.location.state || {
        from: { pathname: "/appointment" },
      };
      dispatch({ type: "LOGIN_SUCCESS", payload: response.data });
      history.push(from);
    } catch (error) {
      alertService.error("Identifiant incorrecte veuillez reesayer");
      setSubmitting(false);
      dispatch({ type: "LOGIN_FAILURE", payload: error });
    }
  };

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <p className="bg-orange-100 w-full">
          Pour prendre rendez-vous, vous devez avoir un compte
        </p>
        <div className="w-full">
          <h1 className="mt-6 text-3xl font-extrabold text-gray-900">
            S'identifier
          </h1>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="flex flex-col my-2 space-y-2">
                <label htmlFor="email">Adresse e-mail</label>
                <Field name="email" type="text" placeholder="Adresse e-mail" />
                <ErrorMessage name="email" />
              </div>
              <div className="flex flex-col my-2 space-y-2">
                <label htmlFor="email">Mot de passe</label>
                <Field
                  name="password"
                  type="password"
                  placeholder="Mot de passe"
                />
                <ErrorMessage name="password" />
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="flex flex-col my-2 space-y-2">
                    <label htmlFor="rememberMe">
                      <Field
                        className="mr-2"
                        name="rememberMe"
                        type="checkbox"
                      />
                      Se souvenir de mon identifiant
                    </label>
                    <ErrorMessage className="text-red-200" name="rememberMe" />
                  </div>
                </div>

                <div className="text-sm">
                  <a
                    href="/forgotpass"
                    className="font-medium text-blue-600 hover:text-blue-500"
                  >
                    Mot de passe oublié?
                  </a>
                </div>
              </div>
              <div className="flex">
                <button
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  type="submit"
                >
                  SE CONNECTER
                </button>
              </div>
            </Form>
          </Formik>
          <div className="my-5 flex items-center justify-between">
            <a
              href="/register"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-blue-500 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Créer votre compte Telmed.
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
