import React from "react";
import Img1 from "../images/1.png";
import Img2 from "../images/2.png";
import Img3 from "../images/3.png";
import Img4 from "../images/4.png";

const Features = () => {
  return (
    <div className="my-20">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto py-16 sm:py-24 lg:py-32 lg:max-w-none">
          <h2 className="text-3xl font-extrabold text-center text-gray-700">Pourquoi prendre rendez-vous avec Telemed ?</h2>
          <div className="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-4 lg:gap-x-6">
            <div className="group relative flex flex-col items-center justify-center">
              <div className="relative bg-white rounded-lg group-hover:opacity-75">
                <img src={Img1} alt="" />
              </div>
              <p className="text-center text-base font-semibold text-gray-900">
                Accédez aux disponibilités de dizaines de milliers de
                professionnels de santé.
              </p>
            </div>
            <div className="group relative flex flex-col items-center justify-center">
              <div className="relative bg-white rounded-lg group-hover:opacity-75">
                <img src={Img2} alt="" />
              </div>
              <p className="text-center text-base font-semibold text-gray-900">
                Prenez rendez vous en ligne, 24h/24 et 7j/7, pour une
                consultation physique ou vidéo.
              </p>
            </div>
            <div className="group relative flex flex-col items-center justify-center">
              <div className="relative bg-white rounded-lg group-hover:opacity-75">
                <img src={Img3} alt="" />
              </div>
              <p className="text-center text-base font-semibold text-gray-900">Recevez des rappels automatiques par SMS ou par email.</p>
            </div>
            <div className="group relative flex flex-col items-center justify-center">
              <div className="relative bg-white rounded-lg group-hover:opacity-75">
                <img src={Img4} alt="" />
              </div>
              <p className="text-center text-base font-semibold text-gray-900">
                Retrouvez votre historique de rendez-vous et vos documents
                médicaux.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
