import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div className="my-20">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-center space-x-4">
          <a href="#/">
            <FontAwesomeIcon className="text-4xl" icon={faFacebook} />
          </a>
          <a href="#/">
            <FontAwesomeIcon className="text-4xl" icon={faInstagram} />
          </a>
          <a href="#/">
            <FontAwesomeIcon className="text-4xl" icon={faTwitter} />
          </a>
          <a href="#/">
            <FontAwesomeIcon className="text-4xl" icon={faLinkedinIn} />
          </a>
          <a href="#/">
            <FontAwesomeIcon className="text-4xl" icon={faYoutube} />
          </a>
        </div>
        
      </div>
    </div>
  );
};

export default Footer;
