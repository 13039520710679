import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import { countryList } from "../../Countries";
import { GET_ME } from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../helpers/apiRequests";

const ParentComponent = () => {
  let submitForm = null;

  const bindSubmitForm = (form) => {
    submitForm = form;
  };

  const handleSubmit = (e) => {
    if (submitForm) {
      submitForm(e);
    }
  };

  return (
    <>
      <button onClick={handleSubmit}>Submit</button>
      <ContactForm bindSubmitForm={bindSubmitForm} />
    </>
  );
};

const ContactForm = ({ bindSubmitForm }) => {
  const [countries, setCountries] = useState(countryList);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    code: "+1",
    telephone: "",
    email: "",
    birthday: new Date(),
    country: "Cote d'Ivoire",
    gender: "M",
  });

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Champ requis"),
    lastName: Yup.string().required("Champ requis"),
    code: Yup.string().required("Champ requis"),
    telephone: Yup.string().required("Champ requis"),
    email: Yup.string()
      .email("Email address invalide")
      .required("Champ requis"),
    birthday: Yup.string().required("Champ requis"),
    country: Yup.string().required("Champ requis"),
    gender: Yup.string().required("Champ requis"),
  });

  const request = FetchWrapper();

  useEffect(async () => {
    // Simulate call to an external API
    const response = await request.get(GET_ME);
    const patient = response.patient;

    let date = new Date(patient.birthday);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    setInitialValues({
      firstName: patient.firstName,
      lastName: patient.lastName,
      code: patient.code,
      telephone: patient.telephone,
      email: patient.email,
      birthday: year + "-" + month + "-" + day,
      country: patient.country,
      gender: patient.gender,
    });
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {(formikProps) => {
        bindSubmitForm(formikProps.submitForm);
        return (
          <Form>
            <div className="form-group">
              <label htmlFor="firstName">Nom:</label>
              <Field type="text" name="firstName" />
              <ErrorMessage name="firstName" />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Prenoms:</label>
              <Field type="text" name="lastName" />
              <ErrorMessage name="lastName" />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <Field type="text" name="email" />
              <ErrorMessage name="email" />
            </div>
            <div className="form-group">
              <label htmlFor="telephone">Telephone:</label>
              <Field type="text" list="code" name="code" />
              <datalist id="code">
                {countries &&
                  countries.map((country, index) => (
                    <option key={index} value={country.dial_code}>
                      {country.dial_code}
                    </option>
                  ))}
              </datalist>
              <ErrorMessage name="code" />
              <Field type="text" name="telephone" />
              <ErrorMessage name="telephone" />
            </div>
            <div className="form-group">
              <label htmlFor="birthday">Date de naissance:</label>
              <Field type="date" name="birthday" />
              <ErrorMessage name="birthday" />
            </div>
            <div className="form-group">
              <label htmlFor="country">Pays:</label>
              <Field type="text" list="country" name="country" />
              <datalist id="country">
                <option value="">Choisir dans la liste</option>
                {countries &&
                  countries.map((country, index) => (
                    <option key={index} value={country.name}>
                      {country.name}
                    </option>
                  ))}
              </datalist>
              <ErrorMessage name="country" />
            </div>
            <div className="form-group">
              <label htmlFor="country">Genre:</label>
              <Field
                as="select"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                name="gender"
                id="gender"
              >
                <option value="">Choisir le sexe</option>
                <option value="M">Masculin</option>
                <option value="F">Feminin</option>
              </Field>
              <ErrorMessage name="gender" />
            </div>

            {/* <button>Submit</button> */}
          </Form>
        );
      }}
    </Formik>
  );
};

export default ParentComponent;
