import React from "react";
import "./FormLayout.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const FormLayout = ({ path, title, description, children }) => {
  return (
    <div className="m-10">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="flex items-center space-x-4">
            <a href={path}>
              <ArrowBackIcon className="w-6 h-6" />
            </a>
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                {title}
              </h2>
              <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  {description}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-10">{children}</div>
      </div>
    </div>
  );
};

export default FormLayout;
