import React, { useState, useEffect } from "react";
import { FetchWrapper } from "../../helpers/apiRequests";
import { GET_HOSPITAL_LIST } from "../../constants/apiEndpoints";
import { PaperClipIcon } from "@heroicons/react/solid";
import ProfileImg from "../../images/profile.png";

const HospitalDetail = () => {
  const request = FetchWrapper();
  const [hospitals, setHospitals] = useState([]);

  useEffect(async () => {
    const response = await request.get(GET_HOSPITAL_LIST);
    setHospitals(response);
  }, []);

  return (
    <div className="m-0">
      {hospitals &&
        hospitals?.map((hospital) => (
          <div key={hospital.id}>
            <section className="w-full">
              <div>
                <div className="w-full bg-gray-300 bg-cover bg-no-repeat bg-center h-[150px]"></div>
                <div className="max-w-6xl mx-auto px-4">
                  <div className="relative flex w-full">
                    <div className="flex flex-1">
                      <div className="mt-[-6rem]">
                        <img
                          className="inline-block h-[144px] w-[144px] rounded-full ring-2 ring-white"
                          src={ProfileImg}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="flex flex-row text-right mt-2">
                      <a
                        className="rounded-full px-4 py-3 bg-blue-500 text-white hover:bg-blue-400"
                        href={`/request/new/${hospital.id}`}
                      >
                        Prendre RDV
                      </a>
                    </div>
                  </div>
                </div>
                <div className="max-w-6xl mx-auto px-4 mt-10">
                  <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {hospital.name}
                      </h3>
                      <p className="mt-1 max-w-2xl text-sm text-gray-500">
                        {hospital.description}
                      </p>
                    </div>
                    <div className="border-t border-gray-200">
                      <dl>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Adresse e-mail
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {hospital.email}
                          </dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Telephone
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {hospital.telephone}
                          </dd>
                        </div>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Honoraire
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            10.000 F.CFA
                          </dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            A propos
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            Nous accueillons les enfants et les adultes pour
                            tous types de soins médicaux généraux (consultation,
                            contrôle annuel, vaccination, bilan de santé). Auusi
                            nous assurons également un suivi des patients dans
                            le temps et les oriente vers des médecins
                            spécialistes en cas de besoin.
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        ))}
    </div>
  );
};

export default HospitalDetail;
