import { useContext, useRef, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "./App.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Profile from "./pages/Profile";
import Appointment from "./pages/Appointment";
import VerifyEmail from "./pages/VerifyEmail";

import { Switch, Route, Redirect } from "react-router-dom";
import { ForgotPassword } from "./pages/ForgotPassword";

import { Alert } from "./components/Alert";
import Header from "./components/Header";

import { AuthContext } from "./context/AuthContext";
import { PrivateRoute } from "./components/PrivateRoute";
import Patient from "./pages/Patient";

import io from "socket.io-client";
import HospitalDetail from "./components/Hospital/HospitalDetail";
import Request from "./pages/Request";
import Demo from "./pages/Demo";

function App() {
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const socket = io("/");
    console.log("Connect");
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className="app-container">
        <Header />
        <Alert />
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/forgotpass">
            <ForgotPassword />
          </Route>
          <Route path="/checkemail">
            <VerifyEmail />
          </Route>
          <Route path="/demo" component={Demo} />
          <PrivateRoute path="/search" component={HospitalDetail} />

          <PrivateRoute path="/request" component={Request} />
          <PrivateRoute path="/appointment" component={Appointment} />
          <PrivateRoute path="/parents" component={Patient} />
          <PrivateRoute path="/profile" component={Profile} />
          <Route path="/">
            <Home />
          </Route>
          <Redirect from="*" to="/" />
        </Switch>
      </div>
    </LocalizationProvider>
  );
}

export default App;
