export const BASE_URL = "http://localhost:5000/api";
// export const BASE_URL = "/api";
export const REGISTER = BASE_URL + "/auth/register";
export const LOGIN = BASE_URL + "/auth/login";
export const LOGOUT = BASE_URL + "/auth/logout";
export const CHECK_EMAIL = BASE_URL + "/auth/verify-email";
export const FORGOT_PASSWORD = BASE_URL + "/auth/forgot-password";
export const GET_ME = BASE_URL + "/auth/me";
export const EDIT_ME = BASE_URL + "/auth/me/update";

export const GET_HOSPITAL_LIST = BASE_URL + "/hospital";
export const GET_HOSPITAL = BASE_URL + "/hospital/";
export const REMOVE_HOSPITAL = BASE_URL + "/hospital/remove/";
export const ADD_HOSPITAL = BASE_URL + "/hospital/create";
export const EDIT_HOSPITAL = BASE_URL + "/hospital/update/";

export const GET_COMPANY_LIST = BASE_URL + "/company";
export const GET_COMPANY = BASE_URL + "/company/";
export const REMOVE_COMPANY = BASE_URL + "/company/remove/";
export const ADD_COMPANY = BASE_URL + "/company/create";
export const EDIT_COMPANY = BASE_URL + "/company/update/";

export const GET_SERVICE_LIST = BASE_URL + "/service";
export const GET_SERVICE = BASE_URL + "/service/";
export const REMOVE_SERVICE = BASE_URL + "/service/remove/";
export const ADD_SERVICE = BASE_URL + "/service/create";
export const EDIT_SERVICE = BASE_URL + "/service/update/";

export const GET_DOCTOR_LIST = BASE_URL + "/doctor";
export const GET_DOCTOR = BASE_URL + "/doctor/";
export const REMOVE_DOCTOR = BASE_URL + "/doctor/remove/";
export const ADD_DOCTOR = BASE_URL + "/doctor/create";
export const EDIT_DOCTOR = BASE_URL + "/doctor/update/";

export const GET_PATIENT_LIST = BASE_URL + "/patient";
export const GET_PATIENT_ME = BASE_URL + "/patient/me";
export const GET_PATIENT = BASE_URL + "/patient/";
export const GET_PATIENT_RAW = BASE_URL + "/patient/raw/";
export const REMOVE_PATIENT = BASE_URL + "/patient/remove/";
export const ADD_PATIENT = BASE_URL + "/patient/create";
export const EDIT_PATIENT = BASE_URL + "/patient/update/";

export const GET_APPOINTMENT_ME = BASE_URL + "/appointment/me";
export const GET_APPOINTMENT_LIST = BASE_URL + "/appointment";
export const GET_APPOINTMENT = BASE_URL + "/appointment/";
export const REMOVE_APPOINTMENT = BASE_URL + "/appointment/remove/";
export const ADD_APPOINTMENT = BASE_URL + "/appointment/create";
export const EDIT_APPOINTMENT = BASE_URL + "/appointment/update/";

export const GET_REQUEST_LIST = BASE_URL + "/request";
export const GET_REQUEST_ME = BASE_URL + "/request/me";
export const GET_REQUEST = BASE_URL + "/request/";
export const REMOVE_REQUEST = BASE_URL + "/request/remove/";
export const ADD_REQUEST = BASE_URL + "/request/create";
export const EDIT_REQUEST = BASE_URL + "/request/update/";
