import React from "react";
import Img6 from "../images/6.png";
import Img7 from "../images/7.png";

const MobileAds = () => {
  return (
    <div className="my-20">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="MobileAds__Left">
            <div className="Logo__Container">
              <h3 className="text-center text-4xl font-bold text-blue-500">
                Telmed.
              </h3>
            </div>
            <div className="text-center py-5 text-gray-500">
              <p>Copyright © 2022 Telemed., tous droits réservés.</p>
            </div>
          </div>
          <div className="flex items-center space-x-2 justify-center">
            <div>
              <a href="#/">
                <img src={Img6} alt="" className="w-40" />
              </a>
            </div>
            <div>
              <a href="#/">
                <img src={Img7} alt="" className="w-40" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileAds;
