import React from "react";
import Banner from "../components/Banner";
import Features from "../components/Features";
import ProSection from "../components/ProSection";
import MobileAds from "../components/MobileAds";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <div>
      <Banner />
      <Features />
      <ProSection />
      <MobileAds />
      <Footer />
    </div>
  );
};

export default Home;
