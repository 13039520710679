import React from "react";
import { Route, Switch } from "react-router-dom";
import PatientDetail from "../components/Patient/PatientDetail";
import PatientForm from "../components/Patient/PatientForm";
import PatientList from "../components/Patient/PatientList";

const Patient = ({ match }) => {
  const { path } = match;
  return (
    <Switch>
      <Route path={`${path}/new`} component={PatientForm} />
      <Route path={`${path}/edit/:id`} component={PatientForm} />
      <Route path={`${path}/:id`} component={PatientDetail} />
      <Route exact path={path} component={PatientList} />
    </Switch>
  );
};

export default Patient;
