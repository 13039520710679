import React, { useState, useEffect } from "react";
import DetailLayout from "../../layouts/DetailLayout";
import { GET_PATIENT } from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../helpers/apiRequests";
import { formatToDate } from "../../helpers/utility";
import Tabs from "../../components/Tabs";

const PatientDetail = ({ match }) => {
  const { path } = match;
  const { id } = match.params;
  const actions = [{ title: "Modifier", url: "/patient/edit/" + id }];
  const [patient, setPatient] = useState({});
  const request = FetchWrapper();
  useEffect(async () => {
    const response = await request.get(GET_PATIENT + id);
    setPatient(response);
  }, []);
  return (
    <DetailLayout
      title={patient.firstName + " " + patient.lastName}
      description={patient.telephone}
      actions={actions}
    >
      <Tabs>
        <div label="Informations">
          <div className="row">
            <div className="col">Nom du patient</div>
            <div className="col">
              {patient.firstName + " " + patient.lastName}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col">Sexe</div>
            <div className="col">{patient.gender}</div>
          </div>
          <div className="row">
            <div className="col">Date de naissance</div>
            <div className="col">{formatToDate(patient.birthday)}</div>
          </div>
          <div className="row">
            <div className="col">Email adresse</div>
            <div className="col">{patient.email}</div>
          </div>
          <div className="row">
            <div className="col">Telephone</div>
            <div className="col">{patient.telephone}</div>
          </div>
          <div className="row">
            <div className="col">Pays</div>
            <div className="col">{patient.country}</div>
          </div>
        </div>
        <div label="Rendez-vous"></div>
        <div label="Compte utilisateur"></div>
      </Tabs>
    </DetailLayout>
  );
};

export default PatientDetail;
