import React, { Fragment, useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  GlobeAltIcon,
  MenuIcon,
  UserCircleIcon,
  UsersIcon,
  SearchIcon,
  QuestionMarkCircleIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  XIcon,
} from "@heroicons/react/solid";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon } from "@heroicons/react/outline";
import ProfileImg from "../images/profile.png";

import { history } from "../helpers/history";
import { AuthContext } from "../context/AuthContext";

const Navbar = () => {
  const { user, isFetching, dispatch, error } = useContext(AuthContext);

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
    history.push("/");
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="bg-blue-500 sticky top-0 z-50">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-between">
          <div className="flex items-center space-x-4">
            <div className="text-xl text-white">
              <a className="py-5 px-3" href="/">
                <h1 className="text-2xl font-bold">Telemed.</h1>
              </a>
            </div>
            <div className="hidden lg:flex items-center space-x-1">
              {/* <a className="py-5 px-3 text-white" href="/">
                Vous êtes professionnel de santé ?
              </a> */}
              <a className="flex items-center py-5 px-3 text-white" href="/">
                <QuestionMarkCircleIcon className="h-6 w-6 mr-2" />
                Besoin d'aide ?
              </a>
            </div>
          </div>
          <div className="hidden lg:flex items-center justify-end">
            {user ? (
              <div className="flex items-center space-x-4">
                <a className="py-5 px-3 text-white" href="/request">
                  {/* <FontAwesomeIcon className="Icon" icon={faCalendar} /> */}
                  Mes Demandes de RDVs
                </a>
                <a className="py-5 px-3 text-white" href="/appointment">
                  {/* <FontAwesomeIcon className="Icon" icon={faHandshake} /> */}
                  Mes RDVs
                </a>
                <a className="py-5 px-3 text-white" href="/parents">
                  {/* <FontAwesomeIcon className="Icon" icon={faUsers} /> */}
                  Mes Proches
                </a>
                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={ProfileImg}
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="/profile"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Mon profile
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            onClick={handleLogout}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Deconnexion
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            ) : (
              <div className="flex items-center space-x-4">
                <a
                  className="flex items-center py-5 px-3 text-white"
                  href="/login"
                >
                  {/* <FontAwesomeIcon className="Icon" icon={faUser} /> */}
                  Se connecter
                </a>
                <a
                  className="flex items-center rounded py-2 px-3 text-blue-500 bg-white hover:bg-gray-100 hover:shadow"
                  href="/register"
                >
                  {/* <FontAwesomeIcon className="Icon" icon={faUser} /> */}
                  S'Inscrire
                </a>
              </div>
            )}
          </div>
          {/* Mobile buttons */}
          <div className="lg:hidden flex items-center">
            {isOpen ? (
              <XIcon
                className="w-6 h-6 text-white"
                onClick={(e) => setIsOpen(!isOpen)}
              />
            ) : (
              <MenuIcon
                className="w-6 h-6 text-white"
                onClick={(e) => setIsOpen(!isOpen)}
              />
            )}
          </div>
        </div>

        {/* Mobile menu */}
        <div className="lg:hidden">
          <div className={isOpen ? "" : "hidden"}>
            {user ? (
              <>
                <a className="block py-4 px-4 text-white" href="/request">
                  {/* <FontAwesomeIcon className="Icon" icon={faCalendar} /> */}
                  Mes Demandes de RDVs
                </a>
                <a className="block py-4 px-4 text-white" href="/appointment">
                  {/* <FontAwesomeIcon className="Icon" icon={faHandshake} /> */}
                  Mes RDVs
                </a>
                <a className="block py-4 px-4 text-white" href="/parents">
                  {/* <FontAwesomeIcon className="Icon" icon={faUsers} /> */}
                  Mes Proches
                </a>
                <a className="block py-4 px-4 text-white" href="/profile">
                  <img
                    className="h-8 w-8 rounded-full"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <span>{user.profile.email}</span>
                </a>
              </>
            ) : (
              <>
                <a
                  className="flex items-center py-4 px-4 text-white hover:text-blue-200"
                  href="/register"
                >
                  {/* <FontAwesomeIcon className="Icon" icon={faUser} /> */}
                  Se connecter
                </a>
                <a
                  className="flex items-center rounded py-4 px-4 text-white hover:text-blue-200"
                  href="/login"
                >
                  {/* <FontAwesomeIcon className="Icon" icon={faUser} /> */}
                  S'Inscrire
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
