import React from "react";
import { Route, Switch } from "react-router-dom";
import RequestForm from "../components/Request/RequestForm";
import RequestList from "../components/Request/RequestList";
import RequestDetail from "../components/Request/RequestDetail";

const Request = ({ match }) => {
  const { path } = match;
  return (
    <Switch>
      <Route path={`${path}/new/:id`} component={RequestForm} />
      <Route path={`${path}/:id`} component={RequestDetail} />
      <Route exact path={path} component={RequestList} />
    </Switch>
  );
};

export default Request;
