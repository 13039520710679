import UserEdit from "../components/Profile/UserEdit";

const Profile = () => {
  let submitForm = null;

  const bindSubmitForm = (form) => {
    submitForm = form;
  };

  const handleSubmit = (e) => {
    if (submitForm) {
      submitForm(e);
    }
  };

  return (
    <div className="max-w-6xl mx-auto px-4">
      <div className="">
        <h2 className="text-2xl py-4">Mon compte</h2>
      </div>
      <div className="">
        <UserEdit bindSubmitForm={bindSubmitForm} />
        <button
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={handleSubmit}
        >
          Enregistrer
        </button>
      </div>
    </div>
  );
};

export default Profile;
