import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { alertService } from "../../services/alert";
// import DatePicker from "@mui/lab/DatePicker";
import { DatePicker } from "@mui/x-date-pickers";
import TimePicker from "@mui/lab/TimePicker";
import Box from "@mui/material/Box";

import { FetchWrapper } from "../../helpers/apiRequests";
import { ADD_REQUEST, GET_PATIENT_ME } from "../../constants/apiEndpoints";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import ModalPatient from "../ModalPatient";
import FormLayout from "../../layouts/FormLayout";
import moment from "moment";

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
export default function RequestForm({ history, location, match }) {
  const request = FetchWrapper();
  const { id } = match.params;
  const [patients, setPatients] = useState([]);
  const [show, setShow] = useState(false);

  const refreshPatientList = async () => {
    const _patients = await request.get(GET_PATIENT_ME);
    setPatients(_patients);
  };

  useEffect(() => {
    refreshPatientList();
  }, []);

  const initialValues = {
    hospital: id,
    date: "",
    motif: "",
    patient: "",
  };

  const validationSchema = Yup.object({
    date: Yup.string().required("Renseigner la date"),
    patient: Yup.string().required("Veuillez selectionner un patient"),
    motif: Yup.string().required("Selectionner le motif"),
  });

  const handleSubmit = async (values, { setStatus, setSubmitting }) => {
    setStatus();
    alertService.clear();
    try {
      const response = await request.post(ADD_REQUEST, values);
      if (response) {
        alertService.success(
          "Enregistrement effectuer! un mail vous sera envoyer pour confirmer votre Rendez-vous",
          {
            keepAfterRouteChange: true,
          }
        );
        const { from } = history.location.state || {
          from: { pathname: "/request" },
        };
        history.push(from);
      }
    } catch (error) {
      setSubmitting(false);
      alertService.error(error.message);
    }
  };

  const handleCancel = (event) => {
    event.preventDefault();
    const { from } = location.state || { from: { pathname: "/" } };
    history.push(from);
  };
  return (
    <FormLayout
      title="Prenez Rendez-vous"
      path=".."
      description="veuillez renseigner les informations ci-dessous"
    >
      <div className="max-w-6xl mx-auto px-4 my-10">
        <div className="md:grid md:grid-cols-1 md:gap-6">
          <div className="mt-1 md:mt-0 md:col-span-2">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {function Render({ isSubmitting, setFieldValue }) {
                const [date, setDate] = React.useState(null);
                const [time, setTime] = React.useState(null);

                const year = new Date().getFullYear();

                return (
                  <Form>
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <div className="grid grid-cols-3 gap-6">
                          <div className="col-span-3 sm:col-span-2">
                            <label
                              htmlFor="company-website"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Motif
                            </label>
                            <div className="mt-1">
                              <Field
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                                as="select"
                                name="motif"
                              >
                                <option value="">Selection du motif</option>
                                <option value="Consultation">
                                  Consultation
                                </option>
                                <option value="Contre Expertise medicale">
                                  Contre Expertise medicale
                                </option>
                                <option value="Sejour Medicale au Maroc">
                                  Sejour Medicale au maroc
                                </option>
                              </Field>
                              <ErrorMessage name="motif" />
                            </div>
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="about"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Date
                          </label>
                          <div className="mt-1">
                            <DatePicker
                              value={date}
                              inputFormat="DD/MM/YYYY"
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                              onChange={(newValue) => {
                                setDate(newValue);
                                setFieldValue("date", newValue);
                              }}
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    className:
                                      "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md",
                                  }}
                                >
                                  <input ref={inputRef} {...inputProps} />
                                  {InputProps?.endAdornment}
                                </Box>
                              )}
                            />

                            <ErrorMessage name="date" />
                          </div>
                          <p className="mt-2 text-sm text-gray-500">
                            Date du Rendez-vous
                          </p>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Choisir un patient
                          </label>
                          {patients && !patients.length ? (
                            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                              <div className="space-y-1 text-center">
                                <div className="flex text-sm text-gray-600">
                                  <label
                                    htmlFor="file-upload"
                                    className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                  >
                                    <span>Pas de données</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="my-4">
                            {patients &&
                              patients.map((patient) => (
                                <label
                                  key={patient.id}
                                  className="flex items-center space-x-4"
                                >
                                  <Field
                                    className=""
                                    type="radio"
                                    name="patient"
                                    value={patient.id}
                                  />
                                  <span>
                                    {patient.firstName + " " + patient.lastName}
                                  </span>
                                </label>
                              ))}
                            {!patients && (
                              <div className="RequestCardView__Spinner">
                                <div className="spinner-border spinner-border-lg align-center"></div>
                              </div>
                            )}
                            <br />
                            <ErrorMessage name="patient" />
                            <div className="mt-4">
                              <a
                                className="rounded-md border-2 px-4 py-3 bg-blue-500 text-white"
                                onClick={() => setShow(true)}
                              >
                                <FontAwesomeIcon
                                  className="Icon"
                                  icon={faPlus}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          Enregistrer
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            <ModalPatient
              title="Nouveau Patient"
              onClose={() => setShow(false)}
              callback={() => refreshPatientList()}
              show={show}
            />
          </div>
        </div>
      </div>
    </FormLayout>
  );
}
