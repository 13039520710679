import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStopwatch,
  faEye,
  faHandshake,
  faHouseUser,
} from "@fortawesome/free-solid-svg-icons";
import Img4 from "../images/4.png";

const ProSection = () => {
  return (
    <div className="my-20">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:flex items-center justify-between">
          <div className="Pro__SectionLeft">
            <h2 className="text-3xl font-extrabold text-center text-gray-700 lg:text-left">
              Vous êtes professionnel de santé ?
            </h2>
            <p className="pb-8 mt-4 max-w-2xl text-md text-gray-500 mx-auto lg:mx-0">
              Contactez-nous. pour gagner en confort de travail.
            </p>
            <ul>
              <li className="flex items-center space-x-4 mb-5">
                <div className="text-2xl text-blue-500 w-10 h-10">
                  <FontAwesomeIcon icon={faStopwatch} />
                </div>
                <div className="Text">
                  Libérez du temps médical grâce à la prise de rendez-vous en
                  ligne.
                </div>
              </li>
              <li className="flex items-center space-x-4 mb-5">
                <div className="text-2xl text-blue-500 w-10 h-10">
                  <FontAwesomeIcon icon={faEye} />
                </div>
                <div className="Text">
                  Développez l'activité de votre cabinet selon vos besoins grâce
                  à une meilleure visibilité en ligne
                </div>
              </li>
              <li className="flex items-center space-x-4 mb-5">
                <div className="text-2xl text-blue-500 w-10 h-10">
                  <FontAwesomeIcon icon={faHandshake} />
                </div>
                <div className="Text">
                  Gagnez en confort de travail au quotidien en réduisant les
                  appels téléphoniques à votre cabinet
                </div>
              </li>
              <li className="flex items-center space-x-4 mb-5">
                <div className="text-2xl text-blue-500 w-10 h-10">
                  <FontAwesomeIcon icon={faHouseUser} />
                </div>
                <div className="Text">
                  Améliorez l'accès aux soins pour vos patients en leur
                  proposant la meilleure des expériences
                </div>
              </li>
            </ul>
          </div>
          <div className="hidden lg:block">
            <img src={Img4} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProSection;
