import React, { useState, useEffect } from "react";
import PageLayout from "../../layouts/PageLayout";
import { Link } from "react-router-dom";

import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined";

import { GET_PATIENT_ME, REMOVE_PATIENT } from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../helpers/apiRequests";

const DoctorList = ({ history, match }) => {
  const { path } = match;
  const actions = [{ url: path + "/new", title: "Nouveau proche" }];

  const request = FetchWrapper();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);

  useEffect(async () => {
    const response = await request.get(GET_PATIENT_ME);
    setRows(response);
  }, []);

  const handleDelete = async (id) => {
    setRows(
      rows.map((x) => {
        if (x.id === id) {
          x.isDeleting = true;
        }
        return x;
      })
    );
    const response = await request.remove(REMOVE_PATIENT + id, { id });
    if (response) {
      setRows((rows) => rows.filter((x) => x.id !== id));
    }
  };

  return (
    <PageLayout title="Proches" description="Mes proches" actions={actions}>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Nom
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Email
                    </th>
                    <th style={{ width: "10%" }}></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {rows &&
                    rows.map((row) => (
                      <tr key={row.id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {row.title} {row.firstName} {row.lastName}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {row.email}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="ButtonIconContainer">
                            <Link
                              className="ButtonIcon"
                              to={`${path}/${row.id}`}
                            >
                              <ContentPasteSearchOutlinedIcon />
                            </Link>
                            <Link
                              className="ButtonIcon"
                              to={`${path}/edit/${row.id}`}
                            >
                              <ModeEditOutlineOutlinedIcon />
                            </Link>
                            <button
                              onClick={() => handleDelete(row.id)}
                              className="ButtonIcon"
                              disabled={row.isDeleting}
                            >
                              {row.isDeleting ? (
                                <span className="spinner-border spinner-border-sm"></span>
                              ) : (
                                <DeleteOutlineOutlinedIcon />
                              )}
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  {!rows && (
                    <tr>
                      <td colSpan="4" className="text-center">
                        <div className="spinner-border spinner-border-lg align-center"></div>
                      </td>
                    </tr>
                  )}
                  {rows && !rows.length && (
                    <tr>
                      <td colSpan="4" className="text-center">
                        <div className="p-2">Aucun enregistrement</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default DoctorList;
