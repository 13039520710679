import React, { useState } from "react";
import FormA from "./FormA";
import FormB from "./FormB";

import { GET_ME } from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../helpers/apiRequests";

export default function DetailForm() {
  const [formData, setFormData] = React.useState({
    formA: { values: null, validated: false },
    formB: { values: null, validated: false },
  });

  const formAref = React.useRef();
  const formBref = React.useRef();

  const [initA, setInitA] = useState({});

  async function handleSubmit() {
    await formAref.current.Submit();
    await formBref.current.Submit();
  }

  function handleChangeFormA(data) {
    setFormData({ ...formData, formA: data });
  }

  function handleChangeFormB(data) {
    setFormData({ ...formData, formB: data });
  }

  const request = FetchWrapper();
  const [user, setUser] = useState([]);

  React.useEffect(async () => {
    const response = await request.get(GET_ME);
    setUser(response);
  }, []);

  return (
    <div className="max-w-6xl mx-auto px-4">
      <h1>Hello CodeSandbox</h1>
      <h2>Start editing to see some magic happen!</h2>

      <FormA onChange={handleChangeFormA} initA={user} refId={formAref} />
      {/* <FormB
        onChange={handleChangeFormB}
        initialValuesB={initialValuesB}
        refId={formBref}
      /> */}

      <button
        onClick={() => {
          console.log(formData);
        }}
        type="button"
      >
        set
      </button>

      <button onClick={handleSubmit} type="button">
        Submit
      </button>
    </div>
  );
}
