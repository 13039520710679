import React from "react";
import { Route, Switch } from "react-router-dom";
import AppointmentList from "../components/Appointment/AppointmentList";
import AppointmentDetail from "../components/Appointment/AppointmentDetail";

const Appointment = ({ match }) => {
  const { path } = match;
  return (
    <Switch>
      <Route path={`${path}/:id`} component={AppointmentDetail} />
      <Route exact path={path} component={AppointmentList} />
    </Switch>
  );
};

export default Appointment;
