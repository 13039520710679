import React, { useState, useEffect } from "react";
import {
  GET_APPOINTMENT,
  REMOVE_APPOINTMENT,
} from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../helpers/apiRequests";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AirlineSeatFlatOutlinedIcon from "@material-ui/icons/AirlineSeatFlatOutlined";
import EventAvailableOutlinedIcon from "@material-ui/icons/EventAvailableOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import VideocamOffOutlinedIcon from "@material-ui/icons/VideocamOffOutlined";

import { formatToTime, formatToDate, countDown } from "../../helpers/utility";

import { Link } from "react-router-dom";

const CompanyDetail = ({ history, match }) => {
  const { path } = match;
  const { id } = match.params;
  const actions = [{ title: "Modifier", url: "/appointment/edit/" + id }];
  const [appointment, setAppointment] = useState({});

  const [rDate, setRDate] = useState(0); //countdown 2 hours.
  const [rHour, setRHour] = useState(0);
  const [rMin, setRMin] = useState(0);
  const [rSec, setRSec] = useState(0);
  const [rExpired, setRExpired] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  const request = FetchWrapper();

  useEffect(() => {
    async function fetchData() {
      const response = await request.get(GET_APPOINTMENT + id);
      setAppointment(response);
      setInterval(function () {
        const EndDate = new Date(response.date);
        let counter = countDown(EndDate);
        setRDate(counter.days);
        setRHour(counter.hours);
        setRMin(counter.minutes);
        setRSec(counter.seconds);
        setRExpired(counter.expired);
      }, 1000);
    }
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    appointment.isDeleting = true;
    const response = await request.remove(REMOVE_APPOINTMENT + id, { id });
    if (response) {
      history.push("/rdv");
    }
  };

  const handleUpload = () => {};

  const handleJoin = () => {
    window.open(
      "https://meet.fatihoune.com/room/" + id,
      "_blank",
      "toolbar=no,scrollbars=yes,resizable=yes,width=800,height=600"
    );
  };

  return (
    <div className="my-10">
      <div>
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mb-5">
          <div className="flex items-center justify-between">
            <div className="Profile__CoverInfo">
              <a className="mr-5" href=".">
                <ArrowBackIcon className="Icon" />
              </a>
              <div className="Profile__Info">
                <div>
                  <h3 className="text-2xl font-bold">{appointment.motif}</h3>
                  <p>
                    {formatToDate(appointment.date)}
                    {" - "}
                    {formatToTime(appointment.date)}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end">
              {appointment.status === false ? (
                <div className="flex items-center justify-end">
                  <div>
                    {rExpired ? (
                      <>
                        <button
                          className="h-15 w-15 flex items-center justify-center rounded-full border-2 border-gray p-2 ml-1 bg-gray-100 text-blue-600 hover:bg-gray-200"
                          onClick={handleJoin}
                        >
                          <VideocamOutlinedIcon />
                        </button>
                      </>
                    ) : (
                      <>
                        <div className="timer-container">
                          <div className="numbers">
                            <span className="num-span">{rDate} Jour(s)</span>
                            <span className="segment"> - </span>
                            <span className="num-span">{rHour} Heure(s)</span>
                            <span className="segment"> : </span>
                            <span className="num-span">{rMin} Minute(s)</span>
                            <span className="segment"> : </span>
                            <span className="num-span">{rSec} Seconde(s)</span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <Link
                    className="h-15 w-15 flex items-center justify-center rounded-full border-2 border-gray p-2 ml-1 bg-gray-100 text-blue-600 hover:bg-gray-200"
                    to={`${path}/edit/${id}`}
                  >
                    <ModeEditOutlineOutlinedIcon />
                    <span className="hidden md:inline">Modifier</span>
                  </Link>
                  <button
                    onClick={() => handleDelete(id)}
                    className="h-15 w-15 flex items-center justify-center rounded-full border-2 border-gray p-2 ml-1 bg-gray-100 text-blue-600 hover:bg-gray-200"
                    disabled={appointment.isDeleting}
                  >
                    {appointment.isDeleting ? (
                      <span className="spinner-border spinner-border-sm"></span>
                    ) : (
                      <>
                        <DeleteOutlineOutlinedIcon />
                        <span className="hidden md:inline">Supprimer</span>
                      </>
                    )}
                  </button>
                </div>
              ) : (
                <>
                  <VideocamOffOutlinedIcon />
                </>
              )}
            </div>
          </div>
        </div>

        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Information generale
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                informations concernant la consultation
              </p>
            </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Motif</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {appointment.motif}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Date</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {formatToDate(appointment.date)}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>

        {appointment.patient && (
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="px-4 py-5 sm:px-6 border-t border-gray-200">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Patient
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Details sur le patient
              </p>
            </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    <PersonOutlinedIcon className="ProfileIcon" />
                    Nom
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {appointment.patient.firstName}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    <PersonOutlinedIcon className="ProfileIcon" />
                    Prenoms
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {appointment.patient.lastName}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    <EmailOutlinedIcon className="ProfileIcon" />
                    Email addresse
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {appointment.patient.email}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    <CallOutlinedIcon className="ProfileIcon" />
                    Telephone
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {appointment.patient.telephone}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        )}
        {appointment.doctor && (
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="px-4 py-5 sm:px-6 my-5 border-t border-gray-200">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Docteur
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Details sur le docteur
              </p>
            </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    <PersonOutlinedIcon className="ProfileIcon" />
                    Nom
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {appointment.doctor.firstName}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    <PersonOutlinedIcon className="ProfileIcon" />
                    Prenoms
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {appointment.doctor.lastName}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    <EmailOutlinedIcon className="ProfileIcon" />
                    Email addresse
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {appointment.doctor.email}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    <CallOutlinedIcon className="ProfileIcon" />
                    Telephone
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {appointment.doctor.telephone}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyDetail;
