import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import { countryList } from "../../Countries";
import { alertService } from "../../services/alert";
import { GET_ME, EDIT_ME } from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../helpers/apiRequests";

const UserEdit = ({ bindSubmitForm }) => {
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Champ requis"),
    lastName: Yup.string().required("Champ requis"),
    email: Yup.string()
      .email("Email address invalide")
      .required("Champ requis"),
    password: Yup.string().required("Renseigner le mot de passe"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Le mot de passe n'est pas conforme")
      .required("Renseigner le mot de passe de confirmation"),
  });

  const request = FetchWrapper();

  useEffect(async () => {
    // Simulate call to an external API
    try {
      const response = await request.get(GET_ME);

      setInitialValues({
        firstName: response.firstName,
        lastName: response.lastName,
        email: response.email,
        password: response.password,
        confirmPassword: response.password,
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleSubmit = async (values) => {
    alertService.clear();
    try {
      const response = await request.post(EDIT_ME, values);
      if (response) {
        console.log(response);
        alertService.success("Modification éffectuer", {
          keepAfterRouteChange: true,
        });
      }
    } catch (error) {
      alertService.success(error, { keepAfterRouteChange: true });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        bindSubmitForm(formikProps.submitForm);
        return (
          <Form>
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <label className="md:col-span-1" htmlFor="firstName">
                Nom:
              </label>
              <div className="md:col-span-1">
                <Field
                  type="text"
                  name="firstName"
                  className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="firstName" />
              </div>
            </div>
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <label htmlFor="lastName">Prenoms:</label>
              <Field
                type="text"
                name="lastName"
                className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              <ErrorMessage name="lastName" />
            </div>
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <label htmlFor="email">Adresse email:</label>
              <Field
                type="text"
                name="email"
                className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              <ErrorMessage name="email" />
            </div>
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <label htmlFor="password">Mot de passe:</label>
              <Field
                type="password"
                name="password"
                className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              <ErrorMessage name="password" />
            </div>
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <label htmlFor="confirmPassword">Confirmez mot de passe:</label>
              <Field
                type="password"
                name="confirmPassword"
                className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              <ErrorMessage name="password" />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserEdit;
