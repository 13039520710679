import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

import { XIcon } from "@heroicons/react/solid";

import { MyTextInput, MySelect } from "../components/Inputs";
import { Formik, Form, Field } from "formik";
import { alertService } from "../services/alert";

import { FetchWrapper } from "../helpers/apiRequests";

import { countryList } from "../Countries";

import {
  ADD_PATIENT,
  EDIT_PATIENT,
  GET_PATIENT,
} from "../constants/apiEndpoints";

import * as Yup from "yup";

const ModalPatient = (props) => {
  const request = FetchWrapper();
  const id = props.id;
  const isAddMode = !id;

  const [countries, setCountries] = useState(countryList);

  const initialValues = {
    role: "patient",
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    birthday: "",
    country: "",
    gender: "",
    work: "",
    maritalStatus: "",
    medicalCover: false,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Champs requis"),
    lastName: Yup.string().required("Champs requis"),
    email: Yup.string()
      .email("Cette adresse email est invalide")
      .required("Champs requis"),
    telephone: Yup.string().required("Champs requis"),
    birthday: Yup.string().required("Champs requis"),
    country: Yup.string().required("Champs requis"),
    gender: Yup.string().required("Champs requis"),
  });

  const handleSubmit = (values, { setStatus, setSubmitting, resetForm }) => {
    setStatus();
    if (isAddMode) {
      createRequest(values, setSubmitting);
    } else {
      updateRequest(id, values, setSubmitting);
    }
  };

  const createRequest = async (values, setSubmitting) => {
    alertService.clear();
    try {
      const response = await request.post(ADD_PATIENT, values);
      if (response) {
        alertService.success("Nouveau proche ajouter", {
          keepAfterRouteChange: true,
        });
        if (props.callback) {
          props.callback(response);
        }
        props.onClose();
      }
    } catch (error) {
      setSubmitting(false);
      alertService.error(error.message);
    }
  };
  const updateRequest = async (id, values, setSubmitting) => {
    alertService.clear();
    try {
      const response = await request.post(EDIT_PATIENT + id, values);
      if (response) {
        alertService.success("Mise a jour effectuer", {
          keepAfterRouteChange: true,
        });
        if (props.callback) {
          props.callback(response);
        }
        props.onClose();
      }
    } catch (error) {
      setSubmitting(false);
      alertService.error(error.message);
    }
  };

  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  return ReactDOM.createPortal(
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <div
        className="fixed flex items-center justify-center z-60 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          onClick={props.onClose}
        ></div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="">
              <div onClick={(e) => e.stopPropagation()}>
                <div className="py-4 flex items-center justify-between">
                  <h4 className="font-bold text-3xl">{props.title}</h4>
                  <div>
                    <button onClick={props.onClose}>
                      <XIcon className="h-8 w-8" />
                    </button>
                  </div>
                </div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {function Render({
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                  }) {
                    const [patient, setPatient] = useState({});

                    useEffect(async () => {
                      if (!isAddMode) {
                        const response = await request.get(GET_PATIENT + id);
                        console.log(response);
                        setPatient(response);
                        if (response) {
                          const fields = [
                            "firstName",
                            "lastName",
                            "gender",
                            "email",
                            "telephone",
                            "birthday",
                            "country",
                          ];
                          fields.forEach((field) => {
                            if (field === "birthday") {
                              let date = new Date(response[field]);
                              let day = date.getDate();
                              let month = date.getMonth() + 1;
                              let year = date.getFullYear();
                              setFieldValue(
                                field,
                                year + "-" + month + "-" + day,
                                false
                              );
                              console.log();
                            } else {
                              setFieldValue(field, response[field], false);
                            }
                          });
                        }
                      }
                    }, []);

                    return (
                      <Form>
                        <p className="mt-1 text-sm text-gray-600">
                          Saisissez les informations ci-dessous
                        </p>
                        <div className="my-5">
                          <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Nom du patient
                            </label>
                            <MyTextInput
                              className="focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                              name="firstName"
                              type="text"
                              placeholder="Nom du patient"
                            />
                          </div>
                          <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Prenoms du patient
                            </label>
                            <MyTextInput
                              className="focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                              name="lastName"
                              type="text"
                              placeholder="Prenoms du patient"
                            />
                          </div>

                          <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Genre
                            </label>
                            <MySelect
                              className="focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                              name="gender"
                            >
                              <option value="">Choisir votre sexe</option>
                              <option value="M">Masculin</option>
                              <option value="F">Feminin</option>
                            </MySelect>
                          </div>

                          <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Adresse e-mail
                            </label>
                            <MyTextInput
                              className="focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                              name="email"
                              type="email"
                              placeholder="Adresse e-mail"
                            />
                          </div>

                          <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Telephone
                            </label>
                            <MyTextInput
                              className="focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                              name="telephone"
                              type="text"
                              placeholder="Numero de telephone"
                            />
                          </div>

                          <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Date de naissance
                            </label>
                            <MyTextInput
                              className="focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                              name="birthday"
                              type="date"
                              placeholder="Entrer votre date de naissance"
                            />
                          </div>

                          <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Nationnalite
                            </label>
                            <MySelect
                              name="country"
                              className="focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                            >
                              <option value="">
                                Choisir votre nationalite
                              </option>
                              <option value="">Choisir dans la liste</option>
                              {countries &&
                                countries.map((country, index) => (
                                  <option key={index} value={country.name}>
                                    {country.name}
                                  </option>
                                ))}
                            </MySelect>
                          </div>
                        </div>
                        <div className="py-3 sm:flex sm:flex-row-reverse">
                          <button
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {isSubmitting && (
                              <span className="spinner-border spinner-border-sm mr-1"></span>
                            )}
                            Enregistrer
                          </button>
                          <button
                            onClick={(e) => {
                              props.onClose();
                              e.stopPropagation();
                            }}
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          >
                            Annuler
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById("root")
  );
};

export default ModalPatient;
